<template>

<div class="summary" :class="{'is-full': !hasInfo}" v-if="recommendation">

	<div class="summary-content">

		<app-title :text="content.title" :is-uppercase="true" />

		<div class="summary-content-text">
			
			<div class="summary-content-text-icon"></div>
			
			<div class="summary-content-text-text">

				<p class="summary-content-text-text-text" v-html="recommendation.text"></p>

				<p class="summary-content-text-text-text is-small" v-if="recommendation.subtext" v-html="recommendation.subtext"></p>

			</div>
		
		</div>

	</div>
	
</div>

</template>

<script>

export default {

	computed: {

		recommendation: function() {

			return this.$store.getters['recommendation']

		},

		content: function() {

			return this.$store.getters['data'].recommendations

		},

		hasInfo: function() {

			return !this.$store.getters['recommendation'] || this.$store.getters['recommendation'].info

		}

	}

}

</script>

<style scoped>

.summary {
	background: transparent linear-gradient(71deg, #2A273E 0%, #554BAC 100%) 0% 0% no-repeat padding-box;
	padding: 80px 10px 180px 10px;
}

.summary.is-full {
	height: 100vh;
}

.is-mobile .summary {
	padding: 60px 0px 20px 0px;
	background: #2A273E;
	border-bottom: 1px solid #fff;
}

.summary-content {
	max-width: 880px;
	width: 100%;
	margin: 0px auto;
}

.summary-content-text {
	display: flex;
	align-items: flex-start;
}

.is-mobile .summary-content-text {
	flex-direction: column;
}

.summary-content-text-icon {
	width: 162px;
	flex-shrink: 0;
	height: 162px;
	margin-right: 40px;
	background-image: url(~@/assets/recommendation.summary.svg);
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-color: #2A273E;
	border-radius: 50%;
}

.is-mobile .summary-content-text-icon {
	background-position: 50% 0px;
	border-radius: 0px;
	width: 100%;
	height: 48px;
	background-size: contain;
	margin: 10px 0px 20px 0px;
}

.summary-content-text-text {
	flex-grow: 1;
}

.summary-content-text-text-text {
	color: #fff;
	font-size: 24px;
	line-height: 32px;
}

.is-mobile .summary-content-text-text-text {
	font-size: 18px;
	line-height: 22px;
	padding: 0px 10px;
	text-align: center;
}

.summary-content-text-text-text.is-small {
	font-size: 18px;
	line-height: 22px;
	margin-top: 20px;
}

</style>
